import React from "react"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import Badge from "@material-ui/core/Badge"

import Hidden from "@material-ui/core/Hidden"

import Wave from "react-wavify"

import SEO from "../components/seo"

import Form from "../components/Form"

const config = {
  fieldsConfig: [
    {
      id: 1,
      label: "Seu nome completo",
      fieldName: "name",
      type: "text",
      isRequired: true,
    },
    {
      id: 2,
      label: "Seu email para contato",
      fieldName: "email",
      type: "email",
      misRequired: true,
    },
    {
      id: 3,
      label: "Seu telefone para contato",
      fieldName: "phone",
      type: "text",
      isRequired: true,
    },
    {
      id: 4,
      label: "Nome da organização",
      fieldName: "organization",
      type: "text",
      isRequired: true,
    },
    {
      id: 5,
      label: "Sua função na organização",
      fieldName: "role",
      type: "text",
      isRequired: true,
    },
  ],
}

function Home() {
  return (
    <>
      <main>
        <SEO title="Grafiame" />
        {/* Capa inicial */}

        <Hero />

        {/* Nosso processo */}
        <div className="me-capa">
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            className="me-card-whatusdo"
          >
            <Box mt={3}>
              <h2 className="me-h2-left">
                O que fazemos<span className="me-the-end">.</span>
              </h2>
            </Box>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Hidden smDown>
                <img
                  alt="Menino segurando o icone Grafiame (G.)"
                  src="/img/illustrations/holdthisgforme.svg"
                  className="me-img-200 me-margin-lr-32"
                ></img>
              </Hidden>

              <div className="me-padding">
                <Box mt={5} boxShadow={3}>
                  <Badge
                    className="me-radius-6"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    color="primary"
                    badgeContent={1}
                  >
                    {
                      <Card>
                        <CardContent>
                          <p>
                            Nossa equipe pedagógica busca entender exatamente as
                            demandas educacionais e de comunicação da sua
                            instituição.
                          </p>
                        </CardContent>
                      </Card>
                    }
                  </Badge>
                </Box>
                <Box mt={5} boxShadow={3}>
                  <Badge
                    className="me-badge"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    color="primary"
                    badgeContent={2}
                  >
                    {
                      <Card>
                        <CardContent>
                          <p>
                            Então, a galera de tecnologia e a equipe pedagógica
                            desenvolvem, com base nos nossos produtos, a
                            plataforma de aprendizagem perfeita para sua
                            organização
                          </p>
                        </CardContent>
                      </Card>
                    }
                  </Badge>
                </Box>
                <Box mt={5} boxShadow={3}>
                  <Badge
                    className="me-badge"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    color="primary"
                    badgeContent={3}
                  >
                    {
                      <Card>
                        <CardContent>
                          <p>
                            E sucesso! Acompanhamos de perto nossa parceria,
                            trazendo sempre novos planos de ação que revelem o
                            verdadeiro potencial da Educação por meio da
                            tecnologia, escrita e ciência.
                          </p>
                        </CardContent>
                      </Card>
                    }
                  </Badge>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* Nossas diretrizes de segurança */}

        <Wave
          className="me-wave"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 2,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />

        {/* <div className="me-x-flex me-flex-wrap-reverse me-content-container"> */}
        <Box p={5} className="me-bg-verzul">
          <Grid container direction="row" justify="center" alignItems="center">
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item md={7} sm={12}>
              <h2 className="me-h2">
                Educação: Está no nosso DNA
                <span className="me-the-end">.</span>
              </h2>
              <p>
                Acreditamos na Educação como base e como caminho fundamental
                para o desenvolvimento humano. Sabemos a importância dela na
                vida de cada pessoa: na capacidade de se relacionar, de
                interpretar informações, de lidar com emoções, de tomar decisões
                e, até mesmo, de obter satisfação pessoal e profissional. Por
                isso, tudo que fazemos aqui na Grafiame tem o objetivo de que a
                Educação revolucione vidas.
              </p>
            </Grid>

            {/* </div> */}
            {/* <div className="me-flex-1 item img"> */}
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <img
                className="me-img-content"
                alt="Garoto sentado na mesa estudando"
                src="/img/illustrations/studyingboy.svg"
              ></img>
              {/* </div> */}
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>

        <Wave
          className="me-inverse"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 2,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />
        {/* Nossas diretrizes de segurança */}

        {/* <div className="me-x-flex me-flex-wrap me-bg-verzul me-content-container"> */}
        {/* <div className="me-flex-1 item img"> */}
        <Box px={5} py={10}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <img
                className="me-img-content"
                alt="Pessoas diferentes com fundos geométricos coloridos"
                src="/img/illustrations/blocksgame.svg"
              ></img>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item md={7} sm={12}>
              <Box ml={5}>
                <h2 className="me-h2-left">
                  Cada caso é um caso<span className="me-the-end">.</span>
                </h2>
                <p>
                  Sabemos que não existe uma fórmula mágica para o
                  desenvolvimento da Educação e da Comunicação e que cada
                  cliente possui um perfil muito específico e necessidades
                  próprias. Então, por isso, a Grafiame trabalha sempre em
                  parceria com seu cliente, diagnosticando suas expectativas e
                  sugerindo caminhos possíveis e inovadores para cada caso. Essa
                  é a nossa fórmula para resultados rápidos e eficientes!
                </p>
              </Box>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
        {/* </div> */}

        {/* Nossas diretrizes de segurança */}

        <Wave
          className="me-wave"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 2,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />
        {/* <div className="me-x-flex me-flex-wrap-reverse me-content-container me-bg-roxo-roxo"> */}
        {/* <div className="me-flex-1 me-text-content item"> */}
        <Box p={5} className="me-bg-verzul">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={7} sm={12}>
              <h2 className="me-h2-left">
                Segurança é o que interessa
                <span className="me-the-end">.</span>
              </h2>
              <p>
                {
                  "Uma das maiores preocupações da equipe de desenvolvimento tecnológico é garantir que os seus dados nunca caiam em mãos erradas. Assim, além de todo o cuidado e carinho que temos com os nossos servidores <3 (onde os dados ficam guardadinhos), só utilizamos o essencial para o funcionamento da nossa plataforma e serviços. Sim, sempre trabalhamos com muita transparência com todos os envolvidos. Dessa forma, todos(as) nós podemos dormir tranquilos(as)."
                }
              </p>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 item img"> */}
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <img
                className="me-img-content"
                alt="Menina segurando uma espada e um escudo"
                src="/img/illustrations/swordgirl.svg"
              ></img>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
        <Wave
          className="me-inverse"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 2,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />
        {/* </div> */}
        {/* Entre em contato */}

        {/* <div className="me-x-flex me-flex-wrap"> */}
        <Box px={5} py={10}>
          <Grid container direction="row" justify="center" alignItems="center">
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item container md={7} sm={12}>
              <h2 className="me-h2-center">
                Vamos facilitar a Educação
                <span className="me-the-end">?</span>
              </h2>

              <Grid container justify="center">
                <Box my={5}>
                  <img
                    className="me-img-content"
                    alt="Aluno sendo ajuda por uma professora a subir uma escada, apoiada em um celular"
                    src="/img/illustrations/thisisahugephone.svg"
                  ></img>
                </Box>
              </Grid>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item container md={5} sm={12} justify="center">
              <Box p={3} boxShadow={3} borderRadius={10}>
                <Grid container style={{ maxWidth: 500 }}>
                  <Form config={config} />
                </Grid>
              </Box>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
        {/* </div> */}
      </main>
      <Footer />
    </>
  )
}

export default Home
