import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"

import Box from "@material-ui/core/Box"

import { Button } from "gatsby-theme-material-ui"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import CircularProgress from "@material-ui/core/CircularProgress"

/**
 * @component Form
 * @props - { object } -  config
 */
const Form = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      "& > *": {
        // margin: theme.spacing(1),
      },
    },
    TextField: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }))

  const classes = useStyles()

  const [mailSent, setmailSent] = useState(false)
  const [error, setError] = useState(null)
  const [formData, setFormData] = useState({})

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })

  const [openA, setOpenA] = React.useState(false)

  const handleClickOpenA = () => {
    setOpenA(true)
  }

  const handleCloseA = () => {
    setOpenA(false)
  }

  const [openB, setOpenB] = React.useState(false)

  const handleClickOpenB = () => {
    setOpenB(true)
  }

  const handleCloseB = () => {
    setOpenB(false)
  }

  /**
   * @function handleFormSubmit
   * @param e { obj } - form event
   * @return void
   */
  const handleFormSubmit = e => {
    e.preventDefault()

    if (!loading) {
      console.log(loading)
      setSuccess(false)
      setLoading(true)
    }

    axios({
      method: "post",
      url: `https://novo-site-c5079.rj.r.appspot.com/contact`,
      contentType: 'application/json',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': 'https://grafiame.com.br' },
      dataType: 'json',
      data: formData,
    })
      .then(response => {
        console.log(response)
        handleClickOpenA()
      })
      .catch(error => {
        console.error(error)
        handleClickOpenB()
      })
      .then(result => {
        setSuccess(false)
        setLoading(false)
      })
  }
  /**
   * @function handleChange
   * @param e { obj } - change event
   * @param field { string } - namve of the field
   * @return void
   */
  const handleChange = (e, field) => {
    let value = e.target.value
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  const {
    title,
    description,
    successMessage,
    errorMessage,
    fieldsConfig,
  } = props.config
  return (
    <div>
      <Grid container justify="center">
        <h2>
          Entre em contato<span className="me-the-end">.</span>
        </h2>
      </Grid>
      <div className="contact-form__container">
        <div>
          <form
            onSubmit={e => handleFormSubmit(e)}
            action="#"
            className={classes.root}
          >
            {fieldsConfig &&
              fieldsConfig.map(field => {
                return (
                  <React.Fragment key={field.id}>
                    {field.type !== "textarea" ? (
                      <TextField
                        label={field.label}
                        name="name"
                        type={field.type}
                        variant="outlined"
                        className={classes.TextField}
                        value={field.name}
                        required
                        onChange={e => handleChange(e, field.fieldName)}
                      />
                    ) : (
                      <React.Fragment>
                        <label>{field.label}</label>
                        <textarea
                          className={field.klassName}
                          placeholder={field.placeholder}
                          onChange={e => handleChange(e, field.fieldName)}
                          value={field.name}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
              })}
            <Box
              marginTop="20px !important"
              display="flex"
              justifyContent="center"
            >
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={(buttonClassname, "me-btn")}
                  disabled={loading}
                  value="sub"
                >
                  Enviar
                </Button>

                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
            <div>
              {mailSent && <div className="sucsess">{successMessage}</div>}
              {error && <div className="error">{errorMessage}</div>}
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={openA}
        onClose={handleCloseA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Solicitação enviada! <3"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Agradecemos o seu contato! Assim que possível, um(a) de nossos
            especialistas entrará em contato pelo endereço de email fornecido ou
            via telefone. Agora é só esperar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseA} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openB}
        onClose={handleCloseB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Erro ao entrar em contato :(
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ops... Infelizmente, não foi possível enviar a solicitação de
            contato (desculpe!). Mas, sem estresse! É só enviar um email para
            contato@grafiame.com.br e nós conversamos diretamente por lá.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseB} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Form
//propTypes for the component
Form.propTypes = {
  config: PropTypes.object.isRequired,
}
