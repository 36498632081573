import React from 'react';

import Header from './Header';

import Lottie from "react-lottie"
import Animation from "./lotties/hero_animation.json"

class Hero extends React.Component {
    render() {
        return (
            <>
                <div className="me-bg-verzul me-capa me-hero">
                    <Header />

                    <div className="me-container"><div className="me-title">
                        <h1>Facilitando a Educação <br />
                            <span className="me-animation">com
                    <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Animation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMinYMid meet",
                                        },
                                    }}
                                    width={330}
                                    height={300}
                                    style={{ margin: "0px" }}
                                /></span></h1>
                    </div></div>


                </div>

            </>
        )
    }
}




export default Hero;